import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import CreateMember from './CreateMember';
import { getSearchParamsStrings } from '../../../utils/index';
import { t } from 'src/i18n/config';
import Popup from '../components/Popup';
import Button from '../components/Button';
import { HiPlus, HiPencilAlt } from 'react-icons/hi';
import { closeLiffWindow } from 'src/lib/liff';
import theme from 'src/theme';

const createFamilyStyleConfig = css`
  .button-content {
    justify-content: center;
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .subtext {
    color: ${({ theme }) => theme.colors.gray[500]};
  }
`;

const SelectContainer = styled.div`
  margin: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`;

const MemberSectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 2px -1px #00000005;
  box-shadow: 0px 1px 15px 0px #0000000a;
  padding: 24px;
  box-sizing: border-box;

  .details {
    display: flex;
    gap: 8px;
    color: ${({ theme }) => theme.colors.gray[900]};
  }
  .name {
    font-weight: ${({ theme }) => theme.font_weight[700]};
  }
  .relation {
    padding: 2px 10px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    font-size: ${({ theme }) => theme.font_size.xs};
    font-weight: ${({ theme }) => theme.font_weight[500]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.font_size.base};
    color: ${({ theme }) => theme.colors.primaryColor[700]};
    font-weight: ${({ theme }) => theme.font_weight.medium};
    gap: 2px;
  }
`;

function MemberSection({ name, editFunc, relation }) {
  return (
    <MemberSectionContainer>
      <div className="details">
        <div className="name">{name}</div>
        <div className="relation">{relation}</div>
      </div>
      <div onClick={editFunc} className="edit-button">
        <HiPencilAlt color={theme.colors.primaryColor[700]} /> 編輯
      </div>
    </MemberSectionContainer>
  );
}

ListMembers.defaultProps = {
  members: [],
};

const defaultState = {
  onCreate: false,
  onEdit: false,
  member: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'onCreate':
      return {
        ...state,
        onCreate: true,
      };
    case 'onDismisss':
      return defaultState;
    default:
      return defaultState;
  }
};

function ListMembers({ members: originalMembers, bindingPhoneNumber }) {
  const searchParams = getSearchParamsStrings();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [members, setMembers] = useState(originalMembers);
  const [showModal, setShowModal] = useState({ type: 'success', show: false });
  useEffect(() => {
    setMembers(originalMembers);
  }, [originalMembers]);

  const _showCreateMemberModalVisible = useCallback(() => {
    dispatch({ type: 'onCreate' });
  }, []);

  const _hideCreateMemberModalVisible = useCallback((res) => {
    if (res != null) {
      setMembers((members) => {
        return [...members, res];
      });
    }
    setShowModal({ ...showModal, show: true });
  }, []);

  if (showModal.show) {
    return (
      <Popup
        type={showModal.type}
        title="新增成功"
        subtext="可以幫家庭成員進行預約囉！"
      >
        <Button
          maxWidth="343px"
          onClick={() => {
            setShowModal({ ...showModal, show: false });
            _showCreateMemberModalVisible();
          }}
        >
          繼續新增家庭成員
        </Button>
        <Button maxWidth="343px" onClick={closeLiffWindow} variant="secondary">
          回到 LINE 主畫面
        </Button>
      </Popup>
    );
  }

  if (state.onCreate) {
    return (
      <CreateMember
        onCreate={_hideCreateMemberModalVisible}
        bindingPhoneNumber={bindingPhoneNumber}
      />
    );
  }

  if (members.length == 0)
    return (
      <Popup
        type="search"
        subtext={t('noFamilyListSubtext')}
        cssConfig={createFamilyStyleConfig}
      >
        <Button maxWidth="343px" onClick={_showCreateMemberModalVisible}>
          <div className="button-content">
            <HiPlus />
            {t('addFamilyMember')}
          </div>
        </Button>
      </Popup>
    );
  return (
    <SelectContainer>
      {members.length > 0 &&
        members.map((member) => {
          const { name, id, relation } = member;
          return (
            <MemberSection
              id={id}
              name={name}
              relation={relation}
              editFunc={() =>
                navigate(`/users/members/edit${searchParams}`, {
                  state: { member, bindingPhoneNumber },
                })
              }
              key={id}
            ></MemberSection>
          );
        })}
      <Button
        maxWidth="343px"
        onClick={_showCreateMemberModalVisible}
        variant="secondary"
      >
        <div className="button-content">
          <HiPlus />
          {t('addFamilyMember')}
        </div>
      </Button>
    </SelectContainer>
  );
}

ListMembers.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

export default ListMembers;
